import React from 'react';
import CarouselAutoPlay from './CarouselAutoPlay';
import Headers from './Header';
import Footer from './Footer';
import ImageGallery from './ImageGallery';

class Client extends React.Component{
    render(){
        return(
          <div class="container-xxl  p-0">
  
  <Headers/>

    <section class="background-section" style={{backgroundImage:'url(/assests/images/client.jpg)',height:"700px",width:"100%"}} >
    <div class="content">
      <h2 style={{color:"skyblue" , lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "28px",
                fontWeight: "400"}}><b>Our-Clients</b></h2>
</div>
<div class="text-block-code">
<div class="row">
<div class="col-md-6">
    </div>
       </div>
</div>
    </section>
<section  >
<div class="container">
  <h3 style={{textAlign:"center", marginTop:"80px"}}><b><br/>OUR GREAT PARTNERS<br/><br/></b></h3>
  <div class="row" style={{marginTop:"50px"}}>
    <div class="col-md-3">
    <img src="assests/images/clientimg/cl1.png" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>

    </div>
    <div class="col-md-3">
    <img src="assests/images/clientimg/cl2.png" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>
    </div>
    
    <div class="col-md-3">
    <img src="assests/images/clientimg/cl3.png" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>

    </div>
    <div class="col-md-3">
    <img src="assests/images/clientimg/cl4.png" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>

    
  </div>
  </div>
  <div class="row" style={{marginTop:"25px",marginBottom:"60px"}}>
    <div class="col-md-3">
    <img src="assests/images/clientimg/gvs.png" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>

    </div>
    
    <div class="col-md-3">
    <img src="assests/images/clientimg/ch.jpg" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>
    </div>
    <div class="col-md-3">
    <img src="assests/images/clientimg/meil.png" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>
    </div>
    
    <div class="col-md-3">
    <img src="assests/images/clientimg/cl9.png" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>

    </div>
    <div class="col-md-3">
    <img src="assests/images/clientimg/sse.jpg" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>

    </div>
    <div class="col-md-3">
    <img src="assests/images/clientimg/scrw.jpg" alt="Norway" style={{height:"100px",marginTop:"15px",width:"100%",border:"2px solid orange"}}/>

    </div>
  </div>
  </div>
  </section>
  
  <Footer/>

  </div>
        
        );}
}

export default Client;
