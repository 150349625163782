import React from "react";
import styles from './CarouselAutoPlay.module.css';
class CarouselAutoPlay extends React.Component{
    render(){
        return(
<html><body style={{backgroundColor:"whitesmoke"}}>
<div className={styles.slider} >
    <div className={styles.track}>
      
    <div className={styles.slide}>
      <img src='assests/images/clientimg/cl1.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/cl2.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/cl3.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/sse.jpg' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/scrw.jpg' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/gvs.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/cl1.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/cl2.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/cl3.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/cl9.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/gvs.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/cl4.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/ch.jpg' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      <div className={styles.slide}>
      <img src='assests/images/clientimg/meil.png' style={{height:"100px",border:"2px solid orange"}}/>
      </div>
      
  
</div>
</div>
</body></html>);
};
}

export default CarouselAutoPlay;