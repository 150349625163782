import React, { useState } from 'react';
import CarouselAutoPlay from './CarouselAutoPlay';
import Headers from './Header';
import './AboutUs.css';
import Footer from './Footer';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ssppcindia.com/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Message Sent Successfully');
            } else {
                alert('Failed to Send Message');
            }
        } catch (error) {
            alert('Error: ' + error.toString());
        }

        setFormData({
            name: '',
            email: '',
            message: '',
        });
    };

    return (
        <div>
            <div className="container-xxl bg-dark p-0">
                <Headers />
                <section
                    className="about-section"
                    style={{ height: '470px', width: '100%', textAlign: 'center' }}
                >
                    <img
                        src="contact-icon.png"
                        style={{ marginTop: '150px', height: '100px', width: '120px' }}
                        alt="Contact Icon"
                    />
                    <h1 style={{ color: 'orange',
                                        textTransform: 'uppercase',
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "26px",
                fontWeight: "400"}}>
                        <b>Contact Us</b>
                    </h1>
                </section>

                <section
                    className="contact_section layout_padding"
                    style={{backgroundImage:'url(/assests/images/galbg.jpg)'}}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{ backgroundColor: 'darkgray' }}>
                                <br />
                                <h4
                                    style={{
                                        color: 'black',
                                        textAlign: 'center',
                                        fontFamily: 'serif',
                                    }}
                                >
                                    CONTACT US
                                </h4>
                                <br />

                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            style={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            style={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="message"
                                            className="message-box"
                                            placeholder="Message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                            style={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <button type="submit" className="btn">
                                            SUBMIT
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6" style={{ textAlign: 'center' }}>
                                <h5
                                    style={{
                                        color: '#be4a06',
                                        textTransform: 'uppercase',
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px",
                fontWeight: "400"
                                    }}
                                >
                                    <b>SRI SHIVA PARVATHI PROJECTS & CONSTRUCTIONS</b>
                                    <br />
                                    ______________________
                                </h5>
                                <br />
                                <h5
                                    style={{
                                        color: '#be4a06',
                                        textTransform: 'uppercase',
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px",
                fontWeight: "400"
                                    }}
                                >
                                    <b>ADDRESS I</b>
                                </h5>
                                <i className="bi bi-geo-alt-fill"></i>
                                <span
                                    style={{
                                       fontFamily: "'Roboto', 'Open Sans', sans-serif",
                                            fontSize: "14px", /* Adjust size as per your need */
                                            fontWeight: "300", /* Light font weight */
                              lineHeight: '1.6', /* Spacing between lines */
                              letterSpacing: '0.3px'
                              ,textAlign:"justify",
                                        color: 'black',
                                    }}
                                >
                                    Regd. Office: Flat No.401, Sai Ratnam Residency,
                                    <br />
                                    Santhi Nagar, Nellore-524004, Andhra Pradesh, India
                                </span>
                                <br />
                                <br />
                                <i className="bi bi-geo-alt-fill"></i>
                                <span
                                    style={{
                                        fontFamily: "'Roboto', 'Open Sans', sans-serif",
                                             fontSize: "14px", /* Adjust size as per your need */
                                             fontWeight: "300", /* Light font weight */
                               lineHeight: '1.6', /* Spacing between lines */
                               letterSpacing: '0.3px'
                               ,textAlign:"justify",
                                         color: 'black',
                                     }}
                                >
                                    Factory: Plot No.37, Auto nagar, Phase-I, Vedaya Palem,
                                    <br />
                                    Nellore-524004, Andhra Pradesh, India
                                </span>
                                <br />
                                <br />
                                <h5
                                    style={{
                                        color: '#be4a06',
                                        textTransform: 'uppercase',
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px",
                fontWeight: "400"
                                    }}
                                >
                                    <b>CALL US</b>
                                </h5>
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                <span
                                    style={{
                                        fontFamily: "'Roboto', 'Open Sans', sans-serif",
                                             fontSize: "16px", /* Adjust size as per your need */
                                             fontWeight: "300", /* Light font weight */
                               lineHeight: '1.6', /* Spacing between lines */
                               letterSpacing: '0.3px'
                               ,textAlign:"justify",
                                         color: 'black',
                                     }}
                                >
                                    +919480624111
                                </span>
                                <br />
                                <br />
                                <h5
                                    style={{
                                        color: '#be4a06',
                                        textTransform: 'uppercase',
                lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "center",
                fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px",
                fontWeight: "400"
                                    }}
                                >
                                    <b>MAIL US</b>
                                </h5>
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <span
                                   style={{
                                    fontFamily: "'Roboto', 'Open Sans', sans-serif",
                                         fontSize: "16px", /* Adjust size as per your need */
                                         fontWeight: "300", /* Light font weight */
                           lineHeight: '1.6', /* Spacing between lines */
                           letterSpacing: '0.3px'
                           ,textAlign:"justify",
                                     color: 'black',
                                 }}
                                >
                                    shivaparvathiprojects.constr@gmail.com
                                </span>
                                <br />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    );
};

export default Contact;
