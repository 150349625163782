// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CarouselAutoPlay_body__8ZGK8{
  min-height: 100vh;
  display: grid;
  place-items: center;
}
.CarouselAutoPlay_slider__3a9FK{
height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items:center;
  overflow: hidden;
  }
.CarouselAutoPlay_track__\\+04Ab {
    display: flex;
    width: calc(250px * 18);
    animation:CarouselAutoPlay_scroll__Lrxf8 40s linear infinite;
  }
  @keyframes CarouselAutoPlay_scroll__Lrxf8 {
    0%{
      transform: translateX(0);
    }
   
    100%{
      transform: translateX(calc(-250px * 9));
    }
  }
  
  .CarouselAutoPlay_slide__B1MSs{
    height: 200px;
    width: 250px;
    display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
  }
  
  img{
    width: 80%;
    transition: transform 3s;
  }
  

  img:hover{
    transform: translatez(20px);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/CarouselAutoPlay.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;AACA;AACA,aAAa;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB;AACF;IACI,aAAa;IACb,uBAAuB;IACvB,4DAAoC;EACtC;EACA;IACE;MACE,wBAAwB;IAC1B;;IAEA;MACE,uCAAuC;IACzC;EACF;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,aAAa;EACf,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB;;EAEA;IACE,UAAU;IACV,wBAAwB;EAC1B;;;EAGA;IACE,2BAA2B;EAC7B","sourcesContent":[".body{\r\n  min-height: 100vh;\r\n  display: grid;\r\n  place-items: center;\r\n}\r\n.slider{\r\nheight: 250px;\r\n  margin: auto;\r\n  position: relative;\r\n  width: 90%;\r\n  display: grid;\r\n  place-items:center;\r\n  overflow: hidden;\r\n  }\r\n.track {\r\n    display: flex;\r\n    width: calc(250px * 18);\r\n    animation:scroll 40s linear infinite;\r\n  }\r\n  @keyframes scroll {\r\n    0%{\r\n      transform: translateX(0);\r\n    }\r\n   \r\n    100%{\r\n      transform: translateX(calc(-250px * 9));\r\n    }\r\n  }\r\n  \r\n  .slide{\r\n    height: 200px;\r\n    width: 250px;\r\n    display: flex;\r\n  align-items: center;\r\n  padding: 15px;\r\n  perspective: 100px;\r\n  }\r\n  \r\n  img{\r\n    width: 80%;\r\n    transition: transform 3s;\r\n  }\r\n  \r\n\r\n  img:hover{\r\n    transform: translatez(20px);\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `CarouselAutoPlay_body__8ZGK8`,
	"slider": `CarouselAutoPlay_slider__3a9FK`,
	"track": `CarouselAutoPlay_track__+04Ab`,
	"scroll": `CarouselAutoPlay_scroll__Lrxf8`,
	"slide": `CarouselAutoPlay_slide__B1MSs`
};
export default ___CSS_LOADER_EXPORT___;
