import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

function ProjectAccordian() {
  return (
    <Accordion defaultActiveKey="0" flush >
      <Accordion.Item eventKey="0" style={{ backgroundColor: '#007bff', color: '#ffffff' }}>
        <Accordion.Header>  33kv line - supply, erection and commissioning of 6.7km - solar</Accordion.Header>
        <Accordion.Body>
        <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "16px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.7px',
  textAlign: "justify"}}>
        33kv line - supply, erection and commissioning of 6.7km length of line with 12.5mtrs Spun poles at Lingampet 132/33KV SS to ACME Solar plant bay location under TSNPDCL & TRANSCO. Contract value Rs.134Lakhs.
      </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1"  style={{ backgroundColor: '#007bff', color: '#ffffff' }}>
        <Accordion.Header>33kv line - supply, erection and commissioning of 5.4km length - solar</Accordion.Header>
        <Accordion.Body>
        <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "16px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.7px',
  textAlign: "justify"}}>
        33kv line - supply, erection and commissioning of 5.4km length of line with 11.0mtrs PSCC poles at Mothkur 132/33KV SS to ACME clean tech solutions private limited, Gurgaon Solar plant bay location under TSSPDCL, Contract value Rs. 38Lakhs.
      </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" style={{ backgroundColor: '#007bff', color: '#ffffff' }}>
        <Accordion.Header>132/33kv Bay extension - supply</Accordion.Header>
        <Accordion.Body>
        <p>
        132/33kv Bay extension - supply, erection and commissioning 1no bay at Godhur 132/33kv sub-station to ACME clean tech solution private limited, Gurgoan TSTRANSCO, contract value Rs.34Lakhs.
      </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" style={{ backgroundColor: '#007bff', color: '#ffffff' }}>
        <Accordion.Header> 132/33kv Bay extension - supply</Accordion.Header>
        <Accordion.Body>
        <p style={{fontFamily:"serif"}}>
        132/33kv Bay extension - supply, erection and commissioning 1no bay at Mothkur 132/33kv sub-station to ACME clean tech solution private limited, Gurgoan TSTRANSCO, contract value Rs.51Lakhs.
      </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" style={{ backgroundColor: '#007bff', color: '#ffffff' }}>
        <Accordion.Header> 33/11KV sub-stations supply</Accordion.Header>
        <Accordion.Body>
        <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "16px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.7px',
  textAlign: "justify"}}>
        33/11KV sub-stations supply, erection and commissioning in M/ s.Vishwanath projects limited, Hyderabad, contract value Rs.164lakhs.
      </p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5" style={{ backgroundColor: '#007bff', color: '#ffffff' }}>
        <Accordion.Header> APTIDCO Infra works</Accordion.Header>
        <Accordion.Body>
        <p style={{fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "16px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.7px',
  textAlign: "justify"}}>
        APTIDCO infra works in APDCEL Srikakulam Vijayanagaram east godavari,west godavari contract value 1800 lakhs.
      </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

      
    
      
    

export default ProjectAccordian;