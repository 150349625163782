import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AboutUs.css';
const Header = () => {

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10); // Adjust this value based on when you want the background to change
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header  className={`transparent_navbar ${isScrolled ? 'solid-background' : 'transparent-background'}`}>
      <Navbar expand="lg" className="px-4 py-3">
        <Navbar.Brand href="index.html" className="p-0">
          <img src="assests/images/logo.png" style={{ height: "60px", width: "100%" }} alt="..." />
        </Navbar.Brand>
        {/* <h4 style={{ fontFamily: "serif", color: "orange" }}>
          <b>SHRI SHIVAPARVATHI</b>
        </h4> */}
        <Navbar.Toggle aria-controls="navbarCollapse">
          <span className="fa fa-bars"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarCollapse">
          <Nav className="mx-auto py-0">
            <Nav.Link href="index.html" className="nav-item" style={{  fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "justify", color: "#2b2929f5"}}>
              <b>HOME</b>
            </Nav.Link>
            <Nav.Link href="/pages" className="nav-item" style={{  fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "justify", color: "#2b2929f5"}}>
              <b>PRODUCTS</b>
            </Nav.Link>
            <Nav.Link href="/client" className="nav-item" style={{  fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "500", /* Light font weight */
  color: "wheat" ,/* Light gray or dark gray */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '1px',
  textAlign: "justify", color: "#2b2929f5"}}>
              <b>CLIENTS</b>
            </Nav.Link>
            <Nav.Link href="/aboutus" className="nav-item" style={{ fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "18px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "justify", color: "#2b2929f5"}}>
              <b>ABOUT US</b>
            </Nav.Link>
            <Nav.Link href="/contactus" className="nav-item" style={{  fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "16px", /* Adjust size as per your need */
                fontWeight: "400", /* Light font weight */
 /* Light gray or dark gray */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '0.3px',
  textAlign: "justify", color: "#2b2929f5"}}>
              <b>CONTACT US</b>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div className="navbar-brand p-0" style={{ color: "#be4a06" }}>
          <div className="img-box" style={{ fontFamily: "'Roboto', 'Open Sans', sans-serif",
                fontSize: "16px", /* Adjust size as per your need */
                fontWeight: "500", /* Light font weight */
/* Light gray or dark gray */
  lineHeight: '1.7', /* Spacing between lines */
  letterSpacing: '1px',
  textAlign: "justify"}}>
            <i className="fa fa-phone" aria-hidden="true"> +919480624111 </i><br />
            <i className="fa fa-envelope" aria-hidden="true"> shivaparvathiprojects.constr@gmail.com</i>
          </div>
        </div>
      </Navbar>
    </header>
  );
}

export default Header;